<template>
  <el-drawer
    :title="`服饰产品库选择-【${dressRow.nickname}-${ this.$utils.parseTime(dressRow.start_at, '{y}年{m}月{d}日') }-${dressRow.platform_name}】`"
    :visible.sync='visible'
    :before-close='onClose'
    custom-class='choose-product-dress-container'>
    <el-form size='mini' inline>
      <el-form-item>
        <el-input v-model='searchCondition.title' clearable placeholder='产品名称' @change='handleQuery'></el-input>
      </el-form-item>

      <el-form-item>
        <el-input placeholder='品牌' v-model='searchCondition.brand_name' @change='handleQuery' clearable
                  style='width: 200px;'/>
      </el-form-item>
      <el-form-item>
        <ProductLeaderSelect v-if='hasAuthority("product_view_all")' ref='refProductLeader'
                             :leader-id.sync='searchCondition.leader_id'
                             leader-type='product-dress'
                             @selected='handleQuery'/>
        <span v-else>{{ userInfo.nickname }} </span>
      </el-form-item>
      <el-form-item>
        <el-select placeholder="请选择是否标品" @change='handleQuery' clearable v-model="searchCondition.wares_standard_is">
          <el-option
              v-for="item in standardOption"
              :key="item.value"
              :label="item.label"
              :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type='primary' icon='el-icon-search' @click='handleQuery'>搜索</el-button>
      </el-form-item>
    </el-form>
    <!--表格-->
    <el-table :data='dataList' height='calc(100% - 105px)' @selection-change='handleSelectionChange' ref='multipleTable'
              style='width: 100%'>
      <el-table-column :selectable='selectable' type='selection' width='55' />
      <el-table-column min-width='360' label='基本信息' fixed='left'>
        <template v-slot='{row:{cover_url,spec_name,title,updated_at,brand_name,leader_name,wares_standard_is},row}'>
          <div class='table-column__basic'>
            <el-image style='width: 76px; height: 76px' :src='cover_url' :preview-src-list='[cover_url]'/>
            <div class='table-column__basic-right'>
              <p class='title'>
                <span v-if='brand_name'>{{ brand_name }}</span>
                {{ title }}
                <el-tag v-if="Number(wares_standard_is) === 1 ">标品
                </el-tag>
              </p>
              <p class='time'>尺码颜色：<b>{{ spec_name }}</b></p>
              <p class='time'>季节性：<b>{{ row.used_season }}</b></p>
              <p class='time'>最后修改于：<b>{{ updated_at|parseTime('{y}/{m}/{d} {h}:{i}') }}</b></p>
              <p class='time'>负责人：<b>{{ leader_name }}</b></p>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop='samples' label='寄样明细' min-width='80' align='center' />
      <el-table-column prop='remark' label='备注' min-width='60' align='center' />
      <el-table-column min-width='110' label='预售补货'>
        <template v-slot='{row:{wares_prep}}'>
          <div v-if='typeof wares_prep==="string"'>
            <div v-for='(tmp,tmp_i) in wares_prep.split(" ； ")' :key='tmp_i'>
              {{ tmp || '' }}
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column min-width='190' label='现货库存'>
        <template v-slot='{row:{wares_exist}}'>
          <div v-if='typeof wares_exist==="string"'>
            <div v-for='(tmp,tmp_i) in wares_exist.split(" ； ")' :key='tmp_i'>
              {{ tmp || '' }}
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop='delivery_cycle' label='发货时效' min-width='80' align='center' show-overflow-tooltip />
      <el-table-column min-width='180' label='价格'>
        <template v-slot='{row:{price,lb_price,commission,support_situation}}'>
          <p>日常价：<b>{{ price || '-' }}</b></p>
          <p>直播价：<b>{{ lb_price || '-' }}</b></p>
          <p>佣金：<b>{{ commission || '-' }}</b></p>
          <p>保价情况：<b>{{ support_situation || '-' }}</b></p>
        </template>
      </el-table-column>
    </el-table>
    <!--        分页-->
    <br />
    <div class='footer' style='display: flex;justify-content: space-between;padding: 0 24px'>
      <pagination :hidden='pageData?.total===0'
                  :background='true'
                  :total.sync='pageData.total'
                  :page.sync='pageData.current_page'
                  :limit.sync='pageData.page_size'
                  @pagination='getList' />
      <el-button type='primary' size='big' @click='handleAdd'>
        添加选品
      </el-button>
    </div>
  </el-drawer>
  <!--  </el-dialog>-->
</template>

<script>
import ProductImage from '@/pages/lb/product/ProductImage'
import CategoryCascader from '@/pages/category/components/CategoryCascader'
import ProductLeaderSelect from '@/pages/lb/components/ProductLeaderSelect.vue'
import { mapGetters } from 'vuex'
import { omitBy } from 'lodash'

export default {
  name: 'DressTable',
  data() {
    return {
      visible: false,
      pageData: {
        total: 0,
        current_page: 1,
        page_size: 10
      },
      dataList: [],
      searchCondition: {},
      multipleSelection: [],
      standardOption: [
        { label: '标品', value: 1, color_value: '#D8FCC0' },
        { label: '非标品', value: 2, color_value: '#E9EDFA' }
      ]
    }
  },
  components: {
    ProductLeaderSelect,
    ProductImage, CategoryCascader
  },
  props: {
    dressRow: {
      type: Object
    }
  },
  computed: {
    ...mapGetters(['userInfo'])
  },
  methods: {
    show() {
      this.getList()
      this.visible = true
    },
    onClose() {
      this.visible = false
      this.$emit('close')
    },
    handleSearchCondition() {
      let params = { ...{}, ...this.searchCondition }
      params = omitBy(params, (value) => value === undefined || value === null || value === '')
      params['lb_id'] = this.dressRow.id
      return params
    },
    handleQuery() {
      this.pageData.current_page = 1
      this.getList()
    },
    async getList() {
      let searchCondition = this.handleSearchCondition()
      Object.assign(searchCondition, { current_page: this.pageData.current_page, page_size: this.pageData.page_size })
      let { list, pages } = await this.$api.getProductDressSelectList(searchCondition)
      this.dataList = list || []
      this.pageData.current_page = pages.current_page || 1
      this.pageData.page_size = pages.page_size || 1
      this.pageData.total = pages.total || 1
    },
    async handleAdd() {
      if (this.multipleSelection.length != 0) {
        this.$confirm(`是否确认添加${this.multipleSelection.length}条产品?`, '警告', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(async () => {
          let id = await this.$api.appendLbDressSelection(this.dressRow.id, this.multipleSelection)
          if (id) {
            this.$message.success('添加成功')
            this.$refs.multipleTable.clearSelection()
            await this.getList()
          }
        }).catch(() => {
          this.msgWarn('取消操作')
        })
      } else this.$message.error('请选择内容')


    },
    // 级联菜单组件change事件
    cascaderChange(v) {
      this.searchCondition.category_id = null
      if (v.length) {
        this.searchCondition.category_id = v[(v.length - 1)]
        this.searchCondition.category_ids = v
      } else {
        this.searchCondition.category_id = null
        this.searchCondition.category_ids = null
      }
      this.handleQuery()
    },
    handleSelectionChange(val) {
      this.multipleSelection = val.map((i) => {
        return i.id
      })
    },
    //重置
    reset() {
      this.searchCondition = {}
      this.getList()
    },
    selectable(row) {
      return !row.lb_selection_id
    }
  }
}
</script>

<style lang='scss'>
.choose-product-dress-container {
  width: 1200px !important;

  .el-drawer__title {
    border-top-left-radius: 10px !important;
  }

  .el-drawer__header {
    font-size: 16px !important;
    color: #333333 !important;
    font-weight: 700;
    text-align: center;
  }

  .el-drawer__body {
    border-bottom-left-radius: 10px !important;
    padding: 0 40px 0 20px;
  }

  .footer {
    position: absolute;
    width: 100%;
    bottom: 0;
    right: 0;
    height: 66px;
    opacity: 1;
    background: #fff;
    box-shadow: 0 -3px 6px 0 rgba(0, 0, 0, .04), 0 2px 4px 0 rgba(0, 0, 0, .5);
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    z-index: 99;
  }

  .el-table {
    p {
      margin: unset;
    }
  }

  .table-column__basic {
    display: flex;
    justify-content: space-between;


    .el-image {
      width: 76px;
      border-radius: 6px;
    }

    &-right {
      width: calc(100% - 100px);

      .title {
        color: #45474d;
        font-weight: 700;
        line-height: 1.4;
        font-size: 13px;
        margin-bottom: 4px;
        margin-top: 2px;

        span {
          font-size: 12px;
          border: 1px solid #feb2c8;
          border-radius: 13px;
          margin-right: 6px;
          padding: 1px 6px;
        }
      }

      .time {
        font-size: 12px;
        color: #8a8e99;
      }
    }
  }
}
</style>
