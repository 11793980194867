<template>
  <el-drawer :title='`${form.id?"修改":"添加"}服饰产品`' :visible.sync='visible' :close-on-press-escape='false'
             :wrapper-closable='false'
             custom-class='product-dress-store-container'>
    <el-form v-loading='loading' ref='form' :model='form' :rules='rules' label-width='110px' size='small'>
      <div style='display: flex;justify-content: space-between;'>
        <div style='width: calc(100% - 260px)'>
          <el-form-item label='产品/品牌' prop='title'>
            <el-input v-model='form.title' placeholder='产品/品牌'>
              <span v-if='form.brand_name' slot='prepend' disabled style='padding: 0 10px;'>
                {{ form.brand_name || '' }}
              </span>
            </el-input>
          </el-form-item>
          <el-form-item label='可播平台' prop='platform_code'>
            <PlatformSelect v-model='form.platform_code' :default-code='form.platform_code' :range-all='true'
                            placeholder='请选择可播平台' @saved='handleSelectPlatform' />
          </el-form-item>
          <el-form-item label='尺码/颜色' prop='spec_name'>
            <el-input v-model='form.spec_name' placeholder='尺码/颜色' />
          </el-form-item>
          <el-form-item label='季节性'>
            <el-radio-group v-model="form.used_season">
              <el-radio label="春"></el-radio>
              <el-radio label="夏"></el-radio>
              <el-radio label="秋"></el-radio>
              <el-radio label="冬"></el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label='日常价' prop='price'>
            <el-input v-model='form.price' placeholder='日常价' />
          </el-form-item>
          <el-form-item label='直播价' prop='lb_price'>
            <el-input v-model='form.lb_price' placeholder='直播价' />
          </el-form-item>
          <el-form-item label='佣金' prop='commission'>
            <el-input v-model='form.commission' placeholder='佣金' />
          </el-form-item>
        </div>
        <ml-upload-image :id.sync='form.cover' :url.sync='form.cover_url' />
      </div>
      <el-form-item label='赠品'>
        <el-input v-model='form.gift' placeholder='赠品/赠品价值' />
      </el-form-item>
      <el-form-item label='保价情况'>
        <el-input v-model='form.support_situation' placeholder='保价情况' />
      </el-form-item>
      <el-form-item label='寄样明细'>
        <el-input v-model='form.samples' placeholder='寄样明细' />
      </el-form-item>
      <el-row>
        <el-col :span='12'>
          <el-form-item label='店铺评分'>
            <el-input v-model='form.company_score' placeholder='店铺评分' clearable />
          </el-form-item>
        </el-col>
        <el-col :span='12'>
          <el-form-item label='联系方式'>
            <el-input v-model='form.contact_info' placeholder='对接人联系方式' />
          </el-form-item>
        </el-col>
        <el-col :span='12'>
          <el-form-item label='预售天数'>
            <el-input v-model='form.wares_prep_day' placeholder='预售天数' />
          </el-form-item>
        </el-col>
        <el-col :span='12'>
          <el-form-item label='预售库存'>
            <el-input v-model='form.wares_prep_qty' placeholder='预售库存' />
          </el-form-item>
        </el-col>
        <el-col :span='12'>
          <el-form-item label='是否补货'>
            <el-radio-group v-model='form.wares_prep_back_is'>
              <el-radio-button label='1'>是</el-radio-button>
              <el-radio-button label='2'>否</el-radio-button>
            </el-radio-group>
          </el-form-item>
        </el-col>
        <el-col :span='12'>
          <el-form-item label='补货周期'>
            <el-input v-model='form.wares_prep_back_cycle' placeholder='补货周期' />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span='12'>
          <el-form-item label='现货库存'>
            <el-input v-model='form.wares_exist_qty' placeholder='现货库存' />
          </el-form-item>
        </el-col>
        <el-col :span='12'>
          <el-form-item label='是否有现货'>
            <el-input v-model='form.wares_exist_is' placeholder='预计直播时是否有现货' />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span='12'>
          <el-form-item label='是否标品'>
            <el-radio-group v-model='form.wares_standard_is'>
              <el-radio-button :label='1'>是</el-radio-button>
              <el-radio-button :label='2'>否</el-radio-button>
            </el-radio-group>
          </el-form-item>
        </el-col>
      </el-row>
      <el-form-item label='发货时效'>
        <el-input v-model='form.delivery_cycle' placeholder='发货时效' />
      </el-form-item>
      <el-form-item label='备注'>
        <el-input type='textarea' v-model='form.remark' placeholder='备注' />
      </el-form-item>
      <el-form-item label='负责人' prop='leader_id'>
        <el-input v-if='form.id&&!editLeader' v-model='form.leader_name' placeholder='负责人'
                  disabled style='width: 200px'>
          <template slot='append'>
            <el-button icon='el-icon-edit' @click='editLeader=true' />
          </template>
        </el-input>
        <UserSelect v-else :leader-id.sync='form.leader_id' :keyword.sync='form.leader_name' />
      </el-form-item>
    </el-form>
    <div class='footer'>
      <ml-button size='medium' @click.native='handleStore' />
    </div>
  </el-drawer>
</template>
<script>
import MlUploadImage from '@/components/MlUploadImg/single.vue'
import MlButton from '@/components/MlButton/index.vue'
import UserSelect from '@/pages/lb/components/UserSelect.vue'
import PlatformSelect from '@/pages/lb/components/PlatformSelect'
import { splitWaresString, jointWaresString } from '@/utils/business/dress'

const rules = {
  title: { required: true, message: '产品/品牌必填', trigger: 'blur' },
  spec_name: { required: true, message: '尺码/颜色必填', trigger: 'blur' },
  price: { required: true, message: '日常价必填', trigger: 'blur' },
  lb_price: { required: true, message: '直播价必填', trigger: 'blur' },
  leader_id: { required: true, message: '负责人', trigger: 'change' },
  platform_code: { required: true, message: '必填', trigger: ['change','blur'] },
  commission: { required: true, message: '必填', trigger: ['change','blur'] },
  refuse_reason: [{ required: true, message: '请输入拒绝理由' }]
}
const ModelForm = {
  id: null,
  cover: null,
  cover_url: null,
  title: null,
  spec_name: null,
  price: null,// 日常价
  lb_price: null,// 直播价
  gift: null,
  commission: null,// 佣金
  support_situation: null,// 保价情况
  samples: null,// 寄样明细
  delivery_cycle: null,// 发货时效
  wares_prep: '',
  wares_exist: '',
  wares_prep_day: '',// 预售天数
  wares_prep_qty: '',// 预售库存
  wares_prep_back_is: '',// 是否补货
  wares_prep_back_cycle: '',// 补货周期
  wares_exist_is: '',// 是否现货
  wares_exist_qty: '',// 现货库存
  remark: null,
  platform_name: [],
  platform_code: [],
  wares_standard_is:2,
  used_season:null

}
const getKey = function() {
  return '' + (new Date().getTime())
}
export default {
  name: 'ProductDressStore',
  components: { UserSelect, MlButton, MlUploadImage, PlatformSelect },
  data() {
    return {
      key: getKey(),
      rules,
      loading: false,
      visible: false,
      editLeader: false,
      form: { ...{}, ...ModelForm }
    }
  },
  methods: {
    assembleForm: function() {
      let params = { ...{}, ...this.form }
      params = { ...params, ...jointWaresString(this.form) }
      this.form = params
    },
    callbackSuccess: function(id) {
      this.$message.success('操作成功')
      this.visible = false
      this.$emit('success', id)
    },
    handleStore: function() {
      this.$refs.form.validate(async valid => {
        if (!valid) {
          this.$message.warning('数据验证失败，请检查必填项数据！')
          return
        }
        this.assembleForm()
        const id = await this.$api.saveProductDressInfo(this.form)
        if (id) {
          this.callbackSuccess(id)
        }
      })
    },

    handleSelectPlatform(val) {
      this.form.platform_code = val ? val.code : []
      this.form.platform_name = val ? val.name : []
    },
    show: async function(id) {
      this.visible = true
      this.loading = true
      if (!id) {
        this.form = { ...{}, ...ModelForm }
      } else {
        const { info } = await this.$api.getProductDressInfo(id)
        const {
          wares_prep_day,
          wares_prep_qty,
          wares_prep_back_is,
          wares_prep_back_cycle,
          wares_exist_is,
          wares_exist_qty
        } = splitWaresString(info)
        info.wares_prep_day = wares_prep_day
        info.wares_prep_qty = wares_prep_qty
        info.wares_prep_back_is = wares_prep_back_is
        info.wares_prep_back_cycle = wares_prep_back_cycle
        info.wares_exist_is = wares_exist_is
        info.wares_exist_qty = wares_exist_qty
        if (info?.brand_name === '') {
          info.contact_user = info?.merchant?.contact_user || ''
          info.contact_info = info?.merchant?.contact_number || ''
          info.delivery_info = info?.merchant?.delivery_info || ''
          info.customer_name = info?.mini_customer_name || ''
          info.brand_name = info?.mini_brand_name || ''
        }
        this.form = info
      }
      this.key = getKey()
      this.loading = false
    }
  }
}
</script>
<style lang='scss'>
.product-dress-store-container {
  width: 1000px !important;

  .el-drawer__title {
    border-top-left-radius: 10px !important;
  }

  .el-drawer__header {
    font-size: 16px !important;
    color: #333333 !important;
    font-weight: 700;
    text-align: center;
  }

  .el-drawer__body {
    border-bottom-left-radius: 10px !important;
    padding: 0 40px 0 20px;
  }

  .footer {
    position: absolute;
    width: 100%;
    bottom: 0;
    right: 0;
    height: 66px;
    opacity: 1;
    background: #fff;
    box-shadow: 0 -3px 6px 0 rgba(0, 0, 0, .04), 0 2px 4px 0 rgba(0, 0, 0, .5);
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    z-index: 99;
  }

  .ml-upload-image {
    width: 240px;
    height: 240px;
  }

  .el-form {
    height: calc(100% - 66px);
    overflow-y: auto;

    .el-radio-group {
      width: 100%;

      .el-radio-button {
        width: 50%;

        span {
          width: 100%;
        }
      }
    }
  }
}
</style>
